var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showComponent)?_c('div',{staticClass:"blog-container "},[_c('div',{staticClass:"tw-w-full lg:tw-w-auto"},[_c('div',{staticClass:"tw-w-full"},[_c('div',{staticClass:"tw-flex header tw-mb-10"},[_c('div',{staticClass:"tw-cursor-pointer",on:{"click":_vm.routeBack}},[_c('Icons',{staticClass:"tw-mr-3 arrow",attrs:{"name":"return-arrow"}})],1),_c('h2',[_vm._v("Create Discount")])]),_c('div',{staticClass:"form-body tw-px-5 tw-py-10"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createNewDiscount)}}},[_c('validation-provider',{attrs:{"name":"Discount Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"post-text"},[_vm._v("Title")]),_c('v-text-field',{staticClass:"post-title-input",attrs:{"type":"text","placeholder":"5% discount","solo":"","hide-details":""},model:{value:(_vm.discountData.name),callback:function ($$v) {_vm.$set(_vm.discountData, "name", $$v)},expression:"discountData.name"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Discount type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"post-text"},[_vm._v("Type")]),_c('v-select',{staticClass:"post-title-input",attrs:{"type":"text","items":_vm.discountTypes,"placeholder":"Percentage","solo":"","hide-details":""},model:{value:(_vm.discountData.type),callback:function ($$v) {_vm.$set(_vm.discountData, "type", $$v)},expression:"discountData.type"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Discount unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"post-text"},[_vm._v("Unit")]),_c('v-text-field',{staticClass:"post-title-input",attrs:{"type":"Number","placeholder":"5000","solo":"","hide-details":""},model:{value:(_vm.discountData.unit),callback:function ($$v) {_vm.$set(_vm.discountData, "unit", $$v)},expression:"discountData.unit"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Discount unit amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"post-text"},[_vm._v("Unit Amount "),(_vm.discountData.type)?_c('span',[_vm._v("("+_vm._s(_vm.discountData.type)+")")]):_vm._e()]),_c('v-text-field',{staticClass:"post-title-input",attrs:{"type":"Number","placeholder":"5","solo":"","hide-details":""},model:{value:(_vm.discountData.unitAmount),callback:function ($$v) {_vm.$set(_vm.discountData, "unitAmount", $$v)},expression:"discountData.unitAmount"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Deployment Channel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"post-text"},[_vm._v("Deployment Channel")]),_c('v-select',{staticClass:"post-title-input",attrs:{"type":"text","items":_vm.voucherDeploymentChannels,"placeholder":"E.g (Web)","solo":"","hide-details":""},model:{value:(_vm.discountData.deploymentChannel),callback:function ($$v) {_vm.$set(_vm.discountData, "deploymentChannel", $$v)},expression:"discountData.deploymentChannel"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Start date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"post-text"},[_vm._v("Start Date")]),_c('v-menu',{ref:"startDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.discountData.startDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.discountData, "startDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.discountData, "startDate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"solo":"","prepend-inner-icon":"mdi-calendar","readonly":"","placeholder":"2022-01-01","hide-details":"","color":"#004aad"},model:{value:(_vm.discountData.startDate),callback:function ($$v) {_vm.$set(_vm.discountData, "startDate", $$v)},expression:"discountData.startDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.startMenu),callback:function ($$v) {_vm.startMenu=$$v},expression:"startMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","min":_vm.minDate,"color":"#004aad"},model:{value:(_vm.discountData.startDate),callback:function ($$v) {_vm.$set(_vm.discountData, "startDate", $$v)},expression:"discountData.startDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#004aad"},on:{"click":function($event){_vm.startMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"#004aad"},on:{"click":function($event){return _vm.$refs.startDateMenu.save(_vm.discountData.startDate)}}},[_vm._v(" OK ")])],1)],1),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"End date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"post-text"},[_vm._v("End Date")]),_c('v-menu',{ref:"endDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.discountData.endDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.discountData, "endDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.discountData, "endDate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"solo":"","prepend-inner-icon":"mdi-calendar","readonly":"","placeholder":"2022-01-01","hide-details":"","color":"#004aad"},model:{value:(_vm.discountData.endDate),callback:function ($$v) {_vm.$set(_vm.discountData, "endDate", $$v)},expression:"discountData.endDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.endMenu),callback:function ($$v) {_vm.endMenu=$$v},expression:"endMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","min":_vm.discountData.startDate,"color":"#004aad"},model:{value:(_vm.discountData.endDate),callback:function ($$v) {_vm.$set(_vm.discountData, "endDate", $$v)},expression:"discountData.endDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#004aad"},on:{"click":function($event){_vm.endMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"#004aad"},on:{"click":function($event){return _vm.$refs.endDateMenu.save(_vm.discountData.endDate)}}},[_vm._v(" OK ")])],1)],1),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Transport Companies","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"post-text"},[_vm._v("Category")]),_c('v-select',{staticClass:"post-title-input",attrs:{"type":"text","items":_vm.discountCategories,"placeholder":"ALL ROUTES","solo":"","hide-details":""},model:{value:(_vm.discountData.discountPromoType),callback:function ($$v) {_vm.$set(_vm.discountData, "discountPromoType", $$v)},expression:"discountData.discountPromoType"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.discountData.discountPromoType ==='SINGLE_TRANSPORT_COMPANY')?_c('validation-provider',{attrs:{"name":"Transport Companies","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"post-text"},[_vm._v("Select Transport Company")]),_c('v-select',{staticClass:"post-title-input",attrs:{"items":_vm.transportCompanies,"item-text":"companyName","item-value":"id","type":"text","placeholder":"GUO","solo":"","hide-details":""},model:{value:(_vm.discountData.transportCompanies),callback:function ($$v) {_vm.$set(_vm.discountData, "transportCompanies", $$v)},expression:"discountData.transportCompanies"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(_vm.discountData.discountPromoType ==='MULTIPLE_TRANSPORT_COMPANY')?_c('validation-provider',{attrs:{"name":"Transport Companies","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"post-text"},[_vm._v("Select Multiple Transport Companies")]),_c('v-combobox',{staticClass:"post-title-input",attrs:{"items":_vm.transportCompanies,"item-text":"companyName","type":"text","placeholder":"GUO","solo":"","hide-details":"","variant":"solo","chips":"","clearable":"","closable-chips":"","multiple":""},scopedSlots:_vm._u([{key:"chip",fn:function(ref){
var props = ref.props;
var item = ref.item;
return [_c('v-chip',_vm._b({},'v-chip',props,false),[_c('strong',[_vm._v(_vm._s(item.companyName))])])]}}],null,true),model:{value:(_vm.discountData.transportCompanies),callback:function ($$v) {_vm.$set(_vm.discountData, "transportCompanies", $$v)},expression:"discountData.transportCompanies"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(_vm.discountData.discountPromoType ==='SINGLE_ROUTE')?_c('validation-provider',{attrs:{"name":"Transport Companies Routes","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"post-text"},[_vm._v("Select Single Route")]),_c('v-select',{staticClass:"post-title-input",attrs:{"items":_vm.tspRoutes,"item-text":_vm.getRouteText,"type":"text","placeholder":"GUO","solo":"","hide-details":""},model:{value:(_vm.discountData.routes),callback:function ($$v) {_vm.$set(_vm.discountData, "routes", $$v)},expression:"discountData.routes"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(_vm.discountData.discountPromoType ==='MULTIPLE_ROUTES')?_c('validation-provider',{attrs:{"name":"Transport Companies Routes","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"post-text"},[_vm._v("Select Multiple Routes")]),_c('v-combobox',{staticClass:"post-title-input",attrs:{"items":_vm.tspRoutes,"item-text":_vm.getRouteText,"type":"text","placeholder":"GUO","solo":"","hide-details":"","variant":"solo","chips":"","clearable":"","closable-chips":"","multiple":""},scopedSlots:_vm._u([{key:"chip",fn:function(ref){
var props = ref.props;
var item = ref.item;
return [_c('v-chip',_vm._b({},'v-chip',props,false),[_c('strong',[_vm._v(_vm._s(_vm.getRouteText(item)))])])]}}],null,true),model:{value:(_vm.discountData.routes),callback:function ($$v) {_vm.$set(_vm.discountData, "routes", $$v)},expression:"discountData.routes"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_c('div',{staticClass:"tw-flex tw-w-full tw-items-end tw-justify-end tw-mt-10"},[_c('v-btn',{staticClass:"post-button",attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? '100%': '',"type":"submit","loading":_vm.loading}},[_vm._v("Create")])],1)],1)]}}],null,false,3219657120)})],1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }